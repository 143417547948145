<template>
  <Toast position="top-right" />
  <div class="p-grid d-p-flex p-jc-center" style="">
    <div class="" style="max-width:600px; width:100%; margin:auto; margin-top: 80px;">
      <div class="text-title p-fluid p-text-center" style="margin: 10px 0px 20px 0px;">
        <h2 class="p-mb-0" style="color:#424242; font-weight: 500;">Recuperación de clave</h2>
      </div>
      <div class="card p-shadow p-radius p-pt-5" style="width:100%;">
        <form @submit.prevent="vericicar_acceso">
          <div class="contenedor p-px-2 p-pb-3 p-d-flex p-jc-around">
            <div class="contenedor-imagen p-d-flex p-fluid p-d-flex p-ai-center p-jc-center">
              <img src="assets/layout/images/logo_login.png" style="height:200px;" />
            </div>

            <!-- step 1 -->
            <div v-if="step==1" class="contenedor-form flex" style="width:320px; padding: 0px 0px 0px 20px;">
              <p style="text-align: justify;" class="p-py-1">
                Para recuperar su contraseña es requerido verificar su registro, para ello enviaremos un código a su correo electrónico.
              </p>
              <div class="p-fluid">
                <div class="p-field p-fluid">
                  <span class="p-input-icon-right">
                    <InputText v-model="correo" placeholder="Ingrese su correo" />
                    <i class="pi pi-envelope" />
                  </span>
                  <small class="text-danger" v-bind:class="{ 'p-hidden': correo_is_valid }">
                    Ingrese su correo
                  </small>
                </div>
              </div>
              <div class="p-fluid p-text-center">
                <Button 
                  :loading="loading" 
                  type="submit" 
                  label="Enviar código" 
                  icon="pi pi-send"
                  iconPos="left" 
                  @click="verificar_correo" 
                  class="p-d-block" />
              </div>
              <div class="p-pt-2">
                <router-link to="/" style="font-size:10pt;">Volver a acceder</router-link>
              </div>
            </div>

            <!-- step 2 -->
            <div v-if="step==2" class="contenedor-form flex" style="width:320px; padding: 0px 0px 0px 20px;">
              <p style="text-align: justify;" class="p-py-1">
                Hemos enviado un código a su correo, verifique y escriba el código recibido en el campo de texto y presione el botón para validar el código ingresado.
              </p>
              <div class="p-fluid">
                <div class="p-field p-fluid">
                  <span class="p-input-icon-right">
                    <InputText v-model="correo" placeholder="Ingrese su correo" />
                    <i class="pi pi-envelope" />
                  </span>
                  <small class="text-danger" v-bind:class="{ 'p-hidden': correo_is_valid }">
                    Ingrese su correo
                  </small>
                </div>
              </div>
              <div class="p-fluid p-text-center">
                <Button 
                  :loading="loading" 
                  type="submit" 
                  label="Enviar código" 
                  icon="pi pi-send"
                  iconPos="left" 
                  @click="verificar_correo" 
                  class="p-d-block" />
              </div>
              <div class="p-pt-2">
                <router-link to="/" style="font-size:10pt;">Volver a acceder</router-link>
              </div>
            </div>

            <!-- step 3 -->
            <div v-if="step==3" class="contenedor-form flex" style="width:320px; padding: 0px 0px 0px 20px;">
              <p style="text-align: justify;" class="p-py-1">
                Hemos enviado un código a su correo, verifique y escriba el código recibido en el campo de texto y presione el botón para validar el código ingresado.
              </p>
              <div class="p-fluid">
                <div class="p-field p-fluid">
                  <span class="p-input-icon-right">
                    <InputText v-model="correo" placeholder="Ingrese su correo" />
                    <i class="pi pi-envelope" />
                  </span>
                  <small class="text-danger" v-bind:class="{ 'p-hidden': correo_is_valid }">
                    Ingrese su correo
                  </small>
                </div>
              </div>
              <div class="p-fluid p-text-center">
                <Button 
                  :loading="loading" 
                  type="submit" 
                  label="Enviar código" 
                  icon="pi pi-send"
                  iconPos="left" 
                  @click="verificar_correo" 
                  class="p-d-block" />
              </div>
              <div class="p-pt-2">
                <router-link to="/" style="font-size:10pt;">Volver a acceder</router-link>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<style>
.contenedor {
  display: flex;
  width: 100% !important;
}

.contenedor-imagen {
  width: 280px;
  border-right: 1px solid #DEE2E6;
}

@media screen and (max-width: 600px) {
  .text-title {
    margin: 20px 0px 0px 0px !important;
  }

  .contenedor {
    display: flex;
    flex-direction: column !important;
    justify-content: center;
    align-items: center;
    width: 100% !important;
  }

  .contenedor-imagen {
    border: none !important;
    width: 100px !important;
  }

  .contenedor-form {
    width: 100% !important;
    padding: 10px 20px !important;
  }
}
</style>

<script>
export default {
  data: () => {
    return {
      loading: false,
      correo: '',
      step: 1,
    }
  },
  methods: {
    async verificar_correo() {
      if( !this.correo_is_valid || this.correo.length == '' )
        return false;

      this.loading = true;

      await this.axios.post("Login/EnviarCodigo/"+this.correo, this.f_login)
      .then(r => {
        console.log(r);
        this.step = 2;
      })
      .catch((err) => {
        console.log(err);
      })
      .then(() => {
        this.loading = false;
      })
    },
  },
  computed: {
    correo_is_valid : function(){
			//eslint-disable-next-line
			let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
			return this.correo == '' ? true : re.test(this.correo)
		},
  }
}
</script>

<style scoped></style>
